/* atkinson-hyperlegible-regular - latin */
@font-face {
	font-family: 'Atkinson Hyperlegible';
	font-style: normal;
	font-weight: 400;
	src:
		local(''),
		url('../../../public/fonts/atkinson-hyperlegible-v9-latin/atkinson-hyperlegible-v9-latin-regular.woff2')
			format('woff2'),
		/* Super Modern Browsers */
			url('../../../public/fonts/atkinson-hyperlegible-v9-latin/atkinson-hyperlegible-v9-latin-regular.woff')
			format('woff'),
		/* Modern Browsers */
			url('../../../public/fonts/atkinson-hyperlegible-v9-latin/atkinson-hyperlegible-v9-latin-regular.ttf')
			format('truetype'); /* Safari, Android, iOS */
}

/* roboto-slab-500 - latin */
@font-face {
	font-family: 'Roboto Slab';
	font-style: normal;
	font-weight: 500;
	src:
		local(''),
		url('../../../public/fonts/roboto-slab-v24-latin/roboto-slab-v24-latin-500.woff2')
			format('woff2'),
		/* Super Modern Browsers */
			url('../../../public/fonts/roboto-slab-v24-latin/roboto-slab-v24-latin-500.woff')
			format('woff'),
		/* Modern Browsers */
			url('../../../public/fonts/roboto-slab-v24-latin/roboto-slab-v24-latin-500.ttf')
			format('truetype'); /* Safari, Android, iOS */
}
